import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './YourComponent.css'; // Import your custom CSS file
import Image1 from '../../Images/Hero (2).jpg'; // Replace with your image path

const YourComponent = () => {
  return (
    <div className="section large-padding-tb overflow-hidden">
      <div className="container">
        <div className="row">
          {/* Left Column with Image Placeholders */}
          <div className="col-lg-6">
            <div className="optech-thumb extra-mr">
             
              <img
                src={Image1}
                alt="Innovative IT Solution" 
                style={{
                  width: '100%',
                  height: '500px', 
                  borderRadius: '15px', 
                  objectFit: 'cover',
                 
                }}
              />
            </div>
          </div>

          {/* Right Column with Text */}
          <div className="col-lg-6 d-flex align-items-start">
            <div className="optech-default-content ml40" style={{ marginTop: '0' }}>
              <h2 className="mb-3">Empowering Businesses with Innovative IT Solution</h2>
              <h5 className="mb-3">
                At Nexonix Solution Pvt. Ltd, we are committed to delivering exceptional IT services tailored to meet the evolving needs of our clients.
              </h5>
              <p className="mb-4">
                In today's fast-paced digital world, businesses require reliable and scalable IT solution to thrive. At Nexonix Solution Pvt. Ltd, we specialize in providing a wide range of IT services designed to help organizations navigate the complexities of technology. Our expertise spans various domains, including software development, cloud computing, IT consulting, and digital transformation.
              </p>
              <p className="mb-4">
                Our team of experienced professionals is dedicated to understanding the unique challenges faced by each client. We believe that successful IT solution are built on a foundation of strong collaboration and open communication. By partnering with our clients, we ensure that we deliver solution that not only meet their immediate needs but also align with their long-term business goals.
              </p>
             
             
             
              <div className="optech-extra-mt">
                <Link to="/About" className="optech-default-btn">
                  <span className="btn-wraper">More About Us</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourComponent;
