import React from 'react';
import Home from './Pages/Home/Home';
import ServicesSection from './Pages/ServicesSection/ServicesSection'
import YourComponent from './Pages/YourComponent/YourComponent';
import ServiceData from './Pages/ServicesData/ServicesData';
import TechSection from './Pages/TechSection/TechSection';
import CtaSection from './Pages/CtaSection/CtaSection';
function Main() {
  return (
    <div>
      <div className='mt-5'>
     <Home/>
     </div>
     <ServicesSection/>
     <YourComponent/>
     <ServiceData />
     <TechSection />
     <CtaSection />
    </div>
  );
}

export default Main;
