import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Container } from 'react-bootstrap'; // Import necessary components
import './Navbar.css';
import logo from '../Images/noxonic image.jpg';

const NavigationBar = () => {
  return (
    <Navbar bg="light" expand="lg" fixed="top" className="header-bar"> {/* Added fixed="top" */}
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            src={logo}
            alt="Waldorf Homeschooling Curriculum | Waldorf home school consulting"
            width="200"
            height="100"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={Link} to="/" className="mr-3">Home</Nav.Link>
            <Nav.Link as={Link} to="/About" className="mr-3">About</Nav.Link>
            <Nav.Link as={Link} to="/Services" className="mr-3">Services</Nav.Link>
            <Nav.Link as={Link} to="/Contactus" className="mr-3">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
