import React from 'react';
// import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CtaSection.css'; // You can add any additional custom styles here
import backgroundImage from '../../Images/work.jpg'; // Adjust the path as needed

const CtaSection = () => {
  return (
    <div
      className="section bg-cover optech-section-padding mt-4"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        height: '400px', // Increase the height as needed
        backgroundSize: 'cover', // Ensure the background covers the whole div
        backgroundPosition: 'center', // Center the image
        display: 'flex', // Use Flexbox for centering content
        justifyContent: 'center', // Center horizontally
        alignItems: 'center' // Center vertically
      }}
    >
      <div className="container text-center">
        <div className="optech-cta-wrap">
          <div className="optech-cta-content">
            <h2>Partner with Nexonix Solution</h2>
            <p>
              At Nexonix Solution Pvt. Ltd, we believe in the power of collaboration. Our team is dedicated to transforming your ideas into reality with innovative technology solution tailored to your needs.
            </p>
            {/* <div className="optech-extra-mt-4 mt-4" data-aos="fade-up" data-aos-duration="800">
              <Link
                to="/contact-us"
                className="optech-default-btn"
                data-text="Let’s Start a Project"
              >
                <span className="btn-wraper">Let’s Start a Project</span>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CtaSection;
