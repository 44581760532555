import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import cyber from '../../Images/Cyber.jpg'; // Background image

const CyberSecurity = () => {
  return (
    <div>
      {/* Background Image Section */}
      <div
        className="breadcrumb-wrapper animate__animated animate__fadeIn"
        style={{
          backgroundImage: `url(${cyber})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '500px',
          color: 'white',
          marginTop: '130px',
        }}
      >
        <div className="container">
          <div className="page-heading text-center">
            <h1 className="animate__animated animate__fadeInUp" style={{ animationDelay: "0.3s", color: 'red' }}>
              Cybersecurity
            </h1>
            <ul className="breadcrumb-items animate__animated animate__fadeInUp" style={{ animationDelay: "0.5s" }}>
              <li>
                <a href="/" style={{ color: 'white' }}>
                  Home
                </a>
              </li>
              <li>
                <i className="fas fa-chevron-right" style={{ color: 'white' }}></i>
              </li>
              <li style={{ color: 'white' }}>
                Cybersecurity
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Cybersecurity Content Section */}
      <div className="container mt-4">
        <div className="section-title title-area mx-auto mb-4">
          <h2 className="title text-center">Cybersecurity</h2>
        </div>
        <div className="terms-conditions-wrapper">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <p>
                Cybersecurity is the practice of protecting systems, networks, and programs from digital attacks. These attacks are usually aimed at accessing, changing, or destroying sensitive information, extorting money from users, or interrupting normal business processes. Implementing strong cybersecurity measures is critical for organizations to safeguard their operations in the digital world.
              </p>
              <h3>1. Importance of Cybersecurity</h3>
              <p>
                As businesses increasingly rely on digital systems and the internet, they face the growing threat of cyber attacks. A breach can result in financial losses, reputational damage, and legal consequences. Strong cybersecurity is essential for protecting sensitive data, customer information, and ensuring business continuity.
              </p>
              <h3>2. Common Cyber Threats</h3>
              <p>
                Common cyber threats include malware, phishing attacks, ransomware, and denial-of-service (DoS) attacks. Hackers use these methods to gain unauthorized access to systems or disrupt services, making it critical for organizations to be vigilant and proactive in defending against these risks.
              </p>
              <h3>3. Network Security</h3>
              <p>
                Network security involves safeguarding the integrity and usability of your network and data. Measures such as firewalls, anti-virus software, and intrusion detection systems can help prevent unauthorized access and attacks from compromising business-critical information.
              </p>
              <h3>4. Data Encryption</h3>
              <p>
                Encryption ensures that data is converted into a code to prevent unauthorized access. Encrypting sensitive data, both in transit and at rest, is a fundamental aspect of cybersecurity, making intercepted data useless to hackers without the decryption keys.
              </p>
              <h3>5. Multi-Factor Authentication (MFA)</h3>
              <p>
                Implementing multi-factor authentication adds an extra layer of security by requiring users to provide two or more verification factors to gain access to systems. This reduces the likelihood of compromised credentials being used to access sensitive data.
              </p>
              <h3>6. Security Awareness Training</h3>
              <p>
                Human error is one of the biggest vulnerabilities in cybersecurity. Regular security awareness training helps employees recognize potential threats such as phishing emails and understand their role in protecting company data. This proactive approach reduces the risk of cyber incidents caused by social engineering.
              </p>
              <h3>7. Incident Response</h3>
              <p>
                Having a well-structured incident response plan is crucial for minimizing the damage from cyber attacks. This plan should outline steps to detect, respond to, and recover from incidents, ensuring the business can quickly resume normal operations and prevent future incidents.
              </p>
              <h3>8. Compliance and Legal Requirements</h3>
              <p>
                Organizations must comply with various regulations such as GDPR, HIPAA, and others that mandate the protection of personal data. Compliance with these regulations is not only important to avoid penalties but also to ensure customer trust and data privacy.
              </p>
              <h3>Conclusion</h3>
              <p>
                In conclusion, cybersecurity is an essential aspect of modern business operations. With the growing frequency and sophistication of cyber attacks, organizations must invest in comprehensive security strategies and foster a culture of vigilance to protect their digital assets and ensure long-term success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CyberSecurity;
