import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import { Link } from 'react-router-dom';
import Services1 from '../../Images/Services.jpg'; // Update this path if needed
import './Services.css'; // Your custom CSS file

const Services = () => {
  return (
    <div>
      {/* Background Image Section */}
      <div
        className="breadcrumb-wrapper animate__animated animate__fadeIn"
        style={{
          backgroundImage: `url(${Services1})`, // Use backticks here for template literals
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '500px',
          color: 'white',
          marginTop: '130px'
        }}
      >
        <div className="container">
          <div className="page-heading text-center">
            <h1 className="animate__animated animate__fadeInUp" style={{ animationDelay: "0.3s", color: 'red' }}>
              Our Services
            </h1>
            <ul className="breadcrumb-items animate__animated animate__fadeInUp" style={{ animationDelay: "0.5s" }}>
              <li>
                <Link to="/" style={{ color: 'white' }}>Home</Link>
              </li>
              <li>
                <i className="fas fa-chevron-right" style={{ color: 'white' }}></i>
              </li>
              <li style={{ color: 'white' }}>Services</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Services Content Section */}
      <div className="container mt-4">
        <div className="section-title title-area mx-auto mb-4">
          <div className="subtitle d-flex justify-content-center">
            <i className="bi bi-arrow-left" aria-hidden="true"></i>
            <span> OUR SERVICES </span>
            <i className="bi bi-arrow-right" aria-hidden="true"></i>
          </div>
          <h2 className="title text-center"> We Provide the Best Quality </h2>
        </div>
        <div className="service-wrapper mb-0">
          <div className="row">
            {[
              {
                title: "Database Security",
                description: "Protect your data with our comprehensive database security solution that prevents unauthorized access and ensures compliance.",
                icon: "bi bi-shield-lock",
                link: "/Database",
                delay: ".3s",
              },
              {
                title: "IT Consultancy",
                description: "Leverage our expert IT consultancy services to optimize your technology strategy and drive business growth.",
                icon: "bi bi-person-check",
                link: "/ITConsultancy",
                delay: ".5s",
                active: true,
              },
              {
                title: "Cyber Security",
                description: "Stay safe from cyber threats with our advanced cybersecurity measures, including risk assessment and incident response.",
                icon: "bi bi-shield-check",
                link: "/CyberSecurity",
                delay: ".7s",
              },
              {
                title: "App Development",
                description: "Transform your ideas into reality with our custom app development services, tailored to meet your business needs.",
                icon: "bi bi-laptop",
                link: "/AppDevelopment",
                delay: ".9s",
              },
              {
                title: "UI/UX Design",
                description: "Enhance user engagement with our innovative UI/UX design services that create intuitive and visually appealing interfaces.",
                icon: "bi bi-brush",
                link: "/UIUXDesign",
                delay: ".3s",
              },
              {
                title: "IT Management",
                description: "Optimize your IT resources with our effective IT management services that ensure operational efficiency and productivity.",
                icon: "bi bi-gear",
                link: "/ITManagement",
                delay: ".5s",
              },
              {
                title: "Digital Marketing",
                description: "Boost your online presence with our comprehensive digital marketing strategies, including SEO, PPC, and social media.",
                icon: "bi bi-bullseye",
                link: "/DigitalMarketing",
                delay: ".7s",
              },
              {
                title: "Data Analysis",
                description: "Make informed decisions with our data analysis services that provide insights into customer behavior and market trends.",
                icon: "bi bi-graph-up",
                link: "/DataAnalysis",
                delay: ".9s",
              },
            ].map((service, index) => (
              <div className={`col-xl-3 col-lg-4 col-md-6 wow fadeInUp`} data-wow-delay={service.delay} key={index}>
                <div className={`service-box-items box-shadow ${service.active ? "active" : ""}`}>
                  <div className="icon">
                    <i className={service.icon} style={{ fontSize: '40px', color: 'red' }}></i>
                  </div>
                  <div className="content">
                    <h4>
                      <Link to={service.link}>{service.title}</Link>
                    </h4>
                    <p>{service.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
