import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './TechSection.css'; // Assuming additional styles are stored in a separate file
import techImage from '../../Images/tech.jpg'; 

const TechSection = () => {
  return (
    <div className="section large-padding-tb4 overflow-hidden ">
      <div className="container">
        <div className="row align-items-center"> {/* Align items in the center */}
          <div className="col-lg-6 order-lg-2">
            <div className="optech-thumb mt-5">
              <img
                data-aos="fade-up"
                data-aos-duration="600"
                src={techImage} // Use the imported image here
                alt="Technology Illustration"
                className="aos-init aos-animate"
                style={{ width: '100%', height: '650px' }} // Ensure responsive image
              />
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-start"> {/* Start aligning from the top */}
            <div className="optech-default-content">
              <h2>Harnessing Technology for Business Growth</h2>
              <p>
                In today's fast-paced digital landscape, the integration of technology into business operations is not just an advantage—it's a necessity. At Nexonix Solution Pvt. Ltd, we believe that leveraging the right technological tools can significantly enhance efficiency, streamline processes, and drive growth. From automation and data analytics to cloud computing and cybersecurity, technology offers businesses the opportunity to innovate and stay competitive.
              </p>
              <p>
                Our approach focuses on understanding the unique needs of each client. We customize our IT solution to align with your business goals, ensuring that every technological investment yields a high return. Whether you are looking to develop a user-friendly web application, enhance your digital marketing strategy, or implement robust IT management practices, we are here to support your journey. 
              </p>
              <div className="optech-extra-mt">
                <ProgressBar title="IT Solution & Management" percentage="86" />
                <ProgressBar title="Website & App Development" percentage="72" />
                <ProgressBar title="SEO & Digital Marketing" percentage="83" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProgressBar = ({ title, percentage }) => {
  return (
    <div className="optech-progress-item mb-4">
      <div className="progress-title">{title}</div>
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: `${percentage}%`,
            backgroundColor: '#dc3545', // Red color for the filled part
          }}
          aria-valuenow={percentage}
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>
      <div className="progress-number">{`${percentage}%`}</div>
      <style>{`
        .progress {
          background-color: #f8f9fa; /* Light grey background */
          border-radius: 5px;
          height: 20px; /* Adjust height of the progress bar */
          position: relative;
        }
        .progress-title {
          color: black; /* Black text color for title */
          font-weight: bold;
          margin-bottom: 5px; /* Spacing between title and progress bar */
        }
        .progress-number {
          color: black; /* Black text color for percentage */
          margin-top: 5px; /* Spacing between progress bar and percentage */
          text-align: right; /* Align the percentage text to the right */
        }
      `}</style>
    </div>
  );
};

export default TechSection;
