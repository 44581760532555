import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Navbar/Navbar';
import Main from './Main';
import About from '../src/Pages/About-us/About'
import Services from '../src/Pages/Services/Services'
import ContactUs from './Pages/Contact/Contact';
import Footer from './Footer/Footer'; 
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './Pages/Terms/Terms';
import Database from './Pages/DatabaseSecurity/DatabaseSecurity'
import ITConsultancy from './Pages/ITConsultancy/ITConsultancy';
import CyberSecurity from './Pages/CyberSecurity/CyberSecurity';
import AppDevelopment from './Pages/AppDevelopment/AppDevelopment';
import UIUXDesign from './Pages/UIUXDesign/UIUXDesign';
import ITManagement from './Pages/ITManagement/ITManagement';
import DigitalMarketing from './Pages/Digital Marketing/Digital Marketing';
import DataAnalysis from './Pages/DataAnalysis/DataAnalysis';
import ScrollToTop from './ScrollToTop';  // Import the ScrollToTop component
import './App.css';

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Add ScrollToTop component */}
      <div>
        <Navbar />

        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/About" element={<About/>} />
          <Route path="/Services" element={<Services/>} />
          <Route path="/Contactus" element={<ContactUs/>} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions/>} />
          <Route path="/Database" element={<Database/>} />
          <Route path="/ITConsultancy" element={<ITConsultancy/>} />
          <Route path="/CyberSecurity" element={<CyberSecurity/>} />
          <Route path="/AppDevelopment" element={<AppDevelopment/>} />
          <Route path="/UIUXDesign" element={<UIUXDesign/>} />
          <Route path="/ITManagement" element={<ITManagement  />} />
          <Route path="/DigitalMarketing" element={<DigitalMarketing  />} />
          <Route path="/DataAnalysis" element={<DataAnalysis />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
