import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import backgroundImage from '../../Images/About.jpg';
import About1 from '../../Images/About1.jpg';
import About2 from '../../Images/About2.jpg';
import './About.css';

const Breadcrumb = () => {
  return (
    <div>
      {/* Background Section */}
      <div
        className="breadcrumb-wrapper "
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '500px',
          color: 'white',
          marginTop: '130px',
        }}
      >
        <div className="container">
          <div className="page-heading text-center">
            <h1 className="animate__animated animate__fadeInUp" style={{ animationDelay: "0.3s", color: 'red' }}>
              About Us
            </h1>
            <ul className="breadcrumb-items animate__animated animate__fadeInUp" style={{ animationDelay: "0.5s" }}>
              <li><a href="/" style={{ color: 'white' }}>Home</a></li>
              <li><i className="fas fa-chevron-right" style={{ color: 'white' }}></i></li>
              <li style={{ color: 'white' }}>About Us</li>
            </ul>
          </div>
        </div>
      </div>

      {/* About Section */}
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-6 animate__animated animate__fadeInLeft">
            <h2 style={{ color: 'red' }}>About Nexonix Solution Pvt. Ltd.</h2>
            <p>
              Nexonix Solution Pvt. Ltd. provides innovative IT services, headquartered in Noida, India. We empower businesses with cutting-edge technologies to thrive in today’s fast-paced world. We offer tailored solution for startups and enterprises to achieve operational excellence and sustainable growth.
            </p>
            <h3 style={{ color: 'red' }}>Our Vision</h3>
            <p>
              We aim to be leaders in technological innovation, creating solution that enable businesses to grow and succeed globally. We are committed to expanding our global reach and driving innovation to help businesses stay competitive.
            </p>
            <h3 style={{ color: 'red' }}>Our Core Values</h3>
            <ul>
              <li><strong>Innovation:</strong> We encourage creativity and forward-thinking in all our projects.</li>
              <li><strong>Integrity:</strong> We prioritize honesty and ethics in all our dealings.</li>
              <li><strong>Quality:</strong> Delivering high-quality, long-lasting solution is our commitment.</li>
              <li><strong>Customer-Centricity:</strong> We put our clients' needs first.</li>
              <li><strong>Collaboration:</strong> Partnering closely with clients and teams to achieve the best results.</li>
            </ul>
            <p>
              Learn more about us at <a href="http://www.nexonixsolution.com" target="_blank" style={{ color: 'blue' }}>www.nexonixsolution.com</a>.
            </p>
          </div>
          <div className="col-md-6 animate__animated animate__fadeInRight">
            <img src={About1 } alt="About Us" className="img-fluid" style={{ borderRadius: '10px' }} />
          </div>
        </div>
      </div>

      {/* Services Section */}
      <div className="section large-padding-tb2 bg-light mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img src={About2} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <div>
                <h2 style={{ color: 'red' }}>Providing IT Solution & Services for Startups</h2>
                <div>
                  <h5>Quality Solution for Business</h5>
                  <p>High-quality solution tailored to meet business needs.</p>
                  <h5>Amazing Expert Teams</h5>
                  <p>Our expert teams ensure business success.</p>
                  <h5>Experienced in IT Solution</h5>
                  <p>We bring years of IT experience for successful outcomes.</p>
                  <a href="/Contactus" className="btn btn-primary mt-4">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
