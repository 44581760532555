import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Home.css'; // Import your custom CSS file

const HeroSection = () => {
  return (
    <div className="optech-hero-section " style={{ }}>
      <div className="container mt-5">
        <div className="row align-items-center">
          <div className="col-lg-8 optech-hero-content">
            <h5>We provide professional IT services</h5>
            <h1 className="large-heading">Innovative Tech Solution<br/> for Your Business</h1>
            <p className='heading'>
              At Nexonix Solution Pvt. Ltd., we empower businesses across various sectors with tailored digital solution designed to meet the demands of a rapidly evolving world.
            </p>
            <div className="optech-extra-mt">
              <div className="optech-btn-wrap">
                {/* Use Link instead of anchor tag */}
                <Link className="optech-default-btn optech-white-btn" to="/services">
                  <span className="btn-wraper">View Services</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            {/* Optional hero image can go here */}
            {/* <div className="optech-hero-thumb">
              <img src="assets/images/hero/hero-thumb1.png" alt="Hero Thumbnail" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
