import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import privacy from '../../Images/Privacy.jpg'; // Background image

const PrivacyPolicy = () => {
  return (
    <div>
      {/* Background Image Section */}
      <div
        className="breadcrumb-wrapper animate__animated animate__fadeIn"
        style={{
          backgroundImage: `url(${privacy})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '500px',
          color: 'white',
          marginTop: '130px',
        }}
      >
        <div className="container">
          <div className="page-heading text-center">
            <h1 className="animate__animated animate__fadeInUp" style={{ animationDelay: "0.3s", color: 'red' }}>
              Privacy Policy
            </h1>
            <ul className="breadcrumb-items animate__animated animate__fadeInUp" style={{ animationDelay: "0.5s" }}>
              <li>
                <a href="/" style={{ color: 'white' }}>
                  Home
                </a>
              </li>
              <li>
                <i className="fas fa-chevron-right" style={{ color: 'white' }}></i>
              </li>
              <li style={{ color: 'white' }}>
                Privacy Policy
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Privacy Policy Content Section */}
      <div className="container mt-4">
        <div className="section-title title-area mx-auto mb-4">
          <h2 className="title text-center">Our Privacy Policy</h2>
        </div>
        <div className="privacy-policy-wrapper">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <p>
                Welcome to the Privacy Policy of Nexonix Solution Pvt. Ltd. This policy outlines how we collect, use, and protect your information. We are committed to safeguarding your privacy and ensuring that your personal information is handled responsibly and in compliance with applicable data protection laws.
              </p>
              <h3>Information Collection</h3>
              <p>
                We may collect personal information such as your name, email address, phone number, and contact details when you engage with our services. This information may be collected through various channels, including our website, mobile applications, and social media platforms. 
              </p>
              <h4>Types of Information We Collect</h4>
              <ul>
                <li>Personal Identifiable Information (PII): Information that can be used to identify you, such as your name, email address, and phone number.</li>
                <li>Non-Personal Information: This includes data that cannot be used to identify you, such as your browser type, device type, and the pages you visit on our website.</li>
                <li>Cookies and Tracking Technologies: We may use cookies, web beacons, and similar technologies to enhance your experience and gather information about usage patterns.</li>
              </ul>
              <h3>Use of Information</h3>
              <p>
                The information we collect is used to provide you with better services, improve our offerings, and communicate with you effectively. Specifically, we may use your information for the following purposes:
              </p>
              <ul>
                <li><strong>To Provide Services:</strong> To deliver the services you request and fulfill your inquiries.</li>
                <li><strong>To Improve Our Services:</strong> We analyze user feedback and engagement to enhance our products and services continually.</li>
                <li><strong>To Communicate:</strong> We may send you emails or notifications about updates, promotions, and important information regarding your account or services.</li>
                <li><strong>To Personalize Your Experience:</strong> By understanding your preferences and behaviors, we can tailor our offerings to meet your needs.</li>
              </ul>
              <h3>Data Protection</h3>
              <p>
                We take appropriate security measures to protect your personal information against unauthorized access or disclosure. This includes implementing technical measures such as encryption, firewalls, and secure server hosting, as well as organizational measures such as staff training and access controls. 
              </p>
              <h4>Data Retention</h4>
              <p>
                We will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. Once your data is no longer needed, we will securely delete or anonymize it.
              </p>
              <h3>Sharing Your Information</h3>
              <p>
                We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except in the following circumstances:
              </p>
              <ul>
                <li>To trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.</li>
                <li>To comply with legal obligations, respond to legal requests, or protect our rights, privacy, safety, or property, and/or that of our users or others.</li>
              </ul>
              <h3>Your Rights</h3>
              <p>
                You have the following rights regarding your personal information:
              </p>
              <ul>
                <li><strong>Access:</strong> You have the right to request copies of your personal information.</li>
                <li><strong>Correction:</strong> You have the right to request that we correct any information you believe is inaccurate or incomplete.</li>
                <li><strong>Deletion:</strong> You have the right to request that we delete your personal information under certain conditions.</li>
                <li><strong>Data Portability:</strong> You have the right to request the transfer of your personal information to another organization, or directly to you, under certain conditions.</li>
              </ul>
              <h3>Cookies and Tracking Technologies</h3>
              <p>
                Our website may use cookies and similar technologies to enhance user experience and analyze usage patterns. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer.
              </p>
              <h3>Children's Privacy</h3>
              <p>
                Our services are not intended for children under the age of 13, and we do not knowingly collect personal information from children. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information from our records.
              </p>
              <h3>Changes to This Privacy Policy</h3>
              <p>
                We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account or by placing a prominent notice on our site.
              </p>
              <h3>Contact Us</h3>
              <p>
                If you have any questions or concerns regarding our Privacy Policy, please contact us at the details provided below:
              </p>
            </div>
          </div>
        </div>

        {/* Company Details Section */}
        {/* <div className="row mt-5 d-flex align-items-stretch">
          <div className="col-lg-6 mx-auto">
            <h3 className="text-center text-danger">Contact Details</h3>
            <div className="contact-details text-black p-4 border rounded" style={{ backgroundColor: 'lightgrey' }}>
              <p>
                <strong>Company Name:</strong> <span className="text-danger">Nexonix Solution Pvt. Ltd</span>
              </p>
              <p>
                <strong>Address:</strong> <span>GNW, GH-03, Sector-02, Noida, UP, 203207, India</span>
              </p>
              <p>
                <strong>Website:</strong> <a href="http://www.nexonixsolution.com" target="_blank" rel="noopener noreferrer" className="text-danger">www.nexonixsolution.com</a>
              </p>
              <p>
                <strong>Email:</strong> <a href="mailto:support@nexonixsolution.com" className="text-danger">support@nexonixsolution.com</a>
              </p>
              <p>
                <strong>Phone:</strong> <span className="text-danger">+91-123-456-7890</span>
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
