import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons
import './ServicesSection.css';

const ServicesSection = () => {
  const services = [
    {
      iconClass: 'bi-people', // Bootstrap icon class
      title: 'Highly Expert Team',
      description: 'Our team consists of industry experts who are skilled in delivering top-notch IT solution tailored to your needs.',
      link: 'https://wa.me/918826937627', // WhatsApp link
    },
    {
      iconClass: 'bi-headset', // Bootstrap icon class
      title: '24/7 Customer Service',
      description: 'We offer round-the-clock customer support to assist you with any inquiries or issues that may arise.',
      link: 'tel:+918826937627', // Phone call link
    },
    {
      // Directly use rupee symbol instead of a Bootstrap icon
      iconClass: 'rupee-symbol', // Custom class for rupee
      title: 'Competitive Pricing',
      description: 'We provide high-quality services at competitive prices, ensuring value for your investment in IT solution.',
    },
  ];

  return (
    <div className="section extra-padding-tb">
      <div className="container">
        {/* Company Name Section */}
        <h2 className="text-center mb-4">Nexonix Solution Pvt Ltd</h2>
        <div className="row">
          {services.map((service, index) => (
            <div key={index} className="col-xl-4 col-md-6">
              {/* Wrap the entire box in <a> */}
              {service.link ? (
                <a href={service.link} target="_blank" rel="noopener noreferrer" className="full-box-link">
                  <div className="optech-iconbox-wrap2">
                    <div className="optech-iconbox-icon2">
                      {/* Use rupee symbol for the pricing */}
                      {service.iconClass === 'rupee-symbol' ? (
                        <span className="icon-size">&#8377;</span> // Rupee symbol
                      ) : (
                        <i className={`bi ${service.iconClass} icon-size`}></i>
                      )}
                    </div>
                    <div className="optech-iconbox-data2">
                      <h5>{service.title}</h5>
                      <p>{service.description}</p>
                    </div>
                  </div>
                </a>
              ) : (
                <div className="optech-iconbox-wrap2">
                  <div className="optech-iconbox-icon2">
                    {service.iconClass === 'rupee-symbol' ? (
                      <span className="icon-size">&#8377;</span> // Rupee symbol
                    ) : (
                      <i className={`bi ${service.iconClass} icon-size`}></i>
                    )}
                  </div>
                  <div className="optech-iconbox-data2">
                    <h5>{service.title}</h5>
                    <p>{service.description}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
