import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import Terms from '../../Images/Privacy.jpg'; // Background image

const TermsAndConditions = () => {
  return (
    <div>
      {/* Background Image Section */}
      <div
        className="breadcrumb-wrapper animate__animated animate__fadeIn"
        style={{
          backgroundImage: `url(${Terms})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '500px',
          color: 'white',
          marginTop: '130px',
        }}
      >
        <div className="container">
          <div className="page-heading text-center">
            <h1 className="animate__animated animate__fadeInUp" style={{ animationDelay: "0.3s", color: 'red' }}>
              Terms and Conditions
            </h1>
            <ul className="breadcrumb-items animate__animated animate__fadeInUp" style={{ animationDelay: "0.5s" }}>
              <li>
                <a href="/" style={{ color: 'white' }}>
                  Home
                </a>
              </li>
              <li>
                <i className="fas fa-chevron-right" style={{ color: 'white' }}></i>
              </li>
              <li style={{ color: 'white' }}>
                Terms and Conditions
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Terms and Conditions Content Section */}
      <div className="container mt-4">
        <div className="section-title title-area mx-auto mb-4">
          <h2 className="title text-center">Terms and Conditions</h2>
        </div>
        <div className="terms-conditions-wrapper">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <p>
                Welcome to the Terms and Conditions page of Nexonix Solution Pvt. Ltd. By accessing or using our services, you agree to comply with and be bound by these terms.
              </p>
              <h3>1. Acceptance of Terms</h3>
              <p>
                By using our services, you confirm that you accept these terms and conditions and that you agree to comply with them. If you do not agree, you must not use our services.
              </p>
              <h3>2. Changes to Terms</h3>
              <p>
                We reserve the right to modify these terms at any time. Any changes will be posted on this page, and it is your responsibility to review these terms periodically.
              </p>
              <h3>3. Services</h3>
              <p>
                Nexonix Solution Pvt. Ltd. provides a range of services that may change from time to time. We strive to keep our services updated, but we do not guarantee their availability.
              </p>
              <h3>4. User Responsibilities</h3>
              <p>
                Users are responsible for ensuring that any information they provide is accurate and up to date. You agree to use our services only for lawful purposes and in a way that does not infringe the rights of others.
              </p>
              <h3>5. Intellectual Property</h3>
              <p>
                All content, trademarks, and other intellectual property on our website and services are owned by Nexonix Solution Pvt. Ltd. or our licensors. You may not reproduce, distribute, or create derivative works from any content without our prior written consent.
              </p>
              <h3>6. Third-Party Links</h3>
              <p>
                Our services may contain links to third-party websites. We do not control these websites and are not responsible for their content. You access them at your own risk.
              </p>
              <h3>7. Limitation of Liability</h3>
              <p>
                Nexonix Solution Pvt. Ltd. will not be liable for any loss or damage that arises from your use of our services or any inability to use them. This includes, without limitation, any loss of profits, data, or goodwill.
              </p>
              <h3>8. Indemnification</h3>
              <p>
                You agree to indemnify and hold Nexonix Solution Pvt. Ltd. harmless from any claims, losses, liabilities, damages, costs, or expenses arising from your use of our services or your breach of these terms.
              </p>
              <h3>9. Governing Law</h3>
              <p>
                These terms and conditions shall be governed by and construed in accordance with the laws of India. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts in India.
              </p>
              <h3>10. Contact Us</h3>
              <p>
                If you have any questions or concerns regarding these Terms and Conditions, please contact us at the details provided below.
              </p>
            </div>
          </div>
        </div>

        {/* Company Details Section */}
        {/* <div className="row mt-5 d-flex align-items-stretch">
          <div className="col-lg-6 mx-auto">
            <h3 className="text-center text-danger">Contact Details</h3>
            <div className="contact-details text-black p-4 border rounded" style={{ backgroundColor: 'lightgrey' }}>
              <p>
                <strong>Company Name:</strong> <span className="text-danger">Nexonix Solution Pvt. Ltd</span>
              </p>
              <p>
                <strong>Address:</strong> <span>GNW, GH-03, Sector-02, Noida, UP, 203207, India</span>
              </p>
              <p>
                <strong>Website:</strong> <a href="http://www.nexonixsolution.com" target="_blank" rel="noopener noreferrer" className="text-danger">www.nexonixsolution.com</a>
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default TermsAndConditions;
