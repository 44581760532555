import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import { Link } from 'react-router-dom';
import Contact from '../../Images/Contact.jpg';
import './Contact.css';

const ContactUs = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const formData = new FormData(e.target);

    fetch('https://formsubmit.co/ajax/office@nexonixsolution.com', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(Object.fromEntries(formData)),
    })
      .then(response => {
        if (response.ok) {
          setFormSubmitted(true);
        } else {
          alert('Form submission failed. Please try again.');
        }
      })
      .catch(error => console.error('Error:', error));
  };

  return (
    <div>
      {/* Background Image Section */}
      <div
        className="breadcrumb-wrapper animate__animated animate__fadeIn"
        style={{
          backgroundImage: `url(${Contact})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '50vh',
          color: 'white',
          marginTop: '130px',
        }}
      >
        <div className="container text-center">
          <h1
            className="animate__animated animate__fadeInUp"
            style={{ animationDelay: '0.3s', color: 'red' }}
          >
            Contact Us
          </h1>
          <ul
            className="breadcrumb-items animate__animated animate__fadeInUp list-inline"
            style={{ animationDelay: '0.5s' }}
          >
            <li className="list-inline-item">
              <Link to="/" className="text-white">Home</Link>
            </li>
            <li className="list-inline-item text-white">
              <i className="fas fa-chevron-right"></i>
            </li>
            <li className="list-inline-item text-white">Contact Us</li>
          </ul>
        </div>
      </div>

      {/* Contact Us Content Section */}
      <div className="container my-5">
        <div className="section-title text-center mb-4">
          <h2 className="title">Get in Touch</h2>
        </div>
        <div className="contact-wrapper p-4 rounded">
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              {!formSubmitted ? (
                <form onSubmit={handleSubmit}>
                  <input type="hidden" name="_subject" value="New Contact Form Submission" />
                  <input type="hidden" name="_captcha" value="false" />
                  <input type="hidden" name="_template" value="table" />
                  
                  <div className="form-group mb-3">
                    <label htmlFor="name" className="text-danger">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      name="name"
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="email" className="text-danger">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Your Email"
                      name="email"
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="mobile" className="text-danger">Mobile Number</label>
                    <input
                      type="tel"
                      className="form-control"
                      id="mobile"
                      placeholder="Your Mobile Number"
                      name="mobile"
                      pattern="[0-9]{10}"
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="message" className="text-danger">Message</label>
                    <textarea
                      className="form-control"
                      id="message"
                      rows="4"
                      placeholder="Your Message"
                      name="message"
                      required
                    ></textarea>
                  </div>
                  <button type="submit" className="btn btn-danger w-100">
                    Send Message
                  </button>
                </form>
              ) : (
                <div className="text-center">
                  <h3 className="text-success">Message Sent Successfully!</h3>
                  <p>Thank you for getting in touch with us. We will respond to you shortly.</p>
                  <Link to="/" className="btn btn-primary mt-3">
                    Go to Home
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Map and Address Section */}
        <div className="row mt-5 d-flex align-items-stretch">
          <div className="col-lg-6">
            <h3 className="text-center text-danger">Our Location</h3>
            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3502.1809356464432!2d77.3795646764438!3d28.624338822850092!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cef8168f80c79%3A0xac0248487d2ba9b2!2sNirala%20Greenshire!5e0!3m2!1sen!2sin!4v1729503990474!5m2!1sen!2sin"
                className="w-100"
                height="400"
                style={{ border: '0' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="col-lg-6">
            <h3 className="text-center text-danger">Contact Details</h3>
            <div className="contact-details p-4 border rounded mt-4 bg-light">
              <p>
                <strong>Company Name:</strong> <span className="text-danger">Nexonix Solution Pvt. Ltd</span>
              </p>
              <p>
                <strong>Address:</strong> <span>GH-03, Greater Noida W Rd, Sector 2, Greater Noida, Uttar Pradesh 201308</span>
              </p>
              <p>
                <strong>Website:</strong>{' '}
                <a
                  href="http://www.nexonixsolution.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-danger"
                >
                  www.nexonixsolution.com
                </a>
              </p>
              <p>
                <strong>Mobile No:</strong> <span className="text-danger">+91-8826937627</span>
              </p>
              <p>
                <strong>Email:</strong>{' '}
                <a href="mailto:office@nexonixsolution.com" className="text-danger">
                  office@nexonixsolution.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
