import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';  // Import Slider from react-slick
import 'bootstrap-icons/font/bootstrap-icons.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const services = [
  {
    icon: "bi bi-palette",  
    title: "UI/UX & Branding Identity",
    description: "Crafting engaging user experiences and visual identities that resonate.",
    link: "/UIUXDesign"
  },
  {
    icon: "bi bi-laptop",  
    title: "Web & Mobile App Development",
    description: "Building scalable web and mobile applications tailored to your business needs.",
    link: "/AppDevelopment"
  },
  {
    icon: "bi bi-megaphone",  
    title: "Digital Marketing Services",
    description: "Enhancing your online presence with strategic digital marketing solution.",
    link: "/DigitalMarketing"
  },
  {
    icon: "bi bi-shield-lock",  
    title: "DataAnalysis",
    description: "Implementing robust security measures to protect your valuable data.",
    link: "/DataAnalysis"
  },
  {
    icon: "bi bi-gear",  
    title: "IT Management Service",
    description: "Optimizing IT infrastructure and management for improved business efficiency.",
    link: "/ITManagement"
  }
];

const ServiceData = () => {

  // Settings for the slider (react-slick)
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,   // Show 4 services at a time
    slidesToScroll: 1,
    autoplay: true,    // Enable autoplay for the carousel
    autoplaySpeed: 1000, // Auto-scroll speed in milliseconds
    responsive: [
      {
        breakpoint: 1024,   // Adjust for medium screens
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,    // Adjust for small screens
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 576,    // Adjust for extra small screens
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <div className="section bg-light1 optech-section-padding" style={{ backgroundColor: '#f8f9fa', paddingTop: '50px' }}>
      <div className="container">
        <div className="optech-section-title center" style={{ textAlign: 'center', marginBottom: '30px' }}>
          <h2 style={{ color: 'black' }}>Our awesome services to give you success</h2>
        </div>
      </div>

      <div className="optech-4column-slider" data-aos="fade-up" data-aos-duration="800">
        <Slider {...settings}>  {/* Use react-slick Slider with settings */}
          {services.map((service, index) => (
            <div key={index} className="slick-slide" style={{ padding: '15px' }}>
              <Link to={service.link} style={{ textDecoration: 'none' }}>  {/* Entire card is clickable */}
                <div className="optech-iconbox-wrap text-center p-3" style={{ background: '#fff', borderRadius: '10px', border: '1px solid #ddd', margin: '10px' }}>
                  <div className="optech-iconbox-icon mb-3">
                    <i className={service.icon} style={{ fontSize: '2.5rem', color: 'red' }}></i>
                  </div>
                  <div className="optech-iconbox-data">
                    <h5 style={{ color: 'black' }}>{service.title}</h5>
                    <p style={{ color: 'grey' }}>{service.description}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ServiceData;
